import { Tab, Tabs } from "@nextui-org/tabs";

const TabSection = () => {
  return (

      <Tabs
        color="primary"
        size="lg"
        aria-label="Tabs colors"
        radius="lg"
        fullWidth
        style={{ maxWidth: 500, textAlign: "center" }}
      >
        {/* <Tab key="privacy-policy" title="Tenant ">
        Tenant is referring to the person who rents the rental home.  <br />  <br />

        The tenant is the person paying rent.

      </Tab>
      <Tab key="terms-of-use" title="Landlord ">
        Landlord is referring to the person who owns the rental home. <br />  <br />

        The landlord is the person collecting rent.
      </Tab> */}
        <Tab key="cookies-policy" title="Hotel" style={{ textAlign: "center" }}>
          <br />
          Book hotels anywhere in the world.
          <br />
          <br />
          Hotel bookings are from 1 day to 30 days.



        </Tab>
        <Tab key="fair-housing" title="Rental">
          <br />

          Book short term rentals around the world.
          <br />
          <br />
          Rental bookings are from 1 day to 30 days.

        </Tab>
        <Tab key="fair-housing2" title="Monthly">


          <br />
          Book fully furnished monthly rentals globally.
          <br />
          <br />
          Monthly bookings are from 1 month to 6 months.

        </Tab>
      </Tabs>

  );
};

export default TabSection;
