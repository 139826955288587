
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ChevronDown, Plus, Minus } from 'lucide-react';
import { MdSearch } from 'react-icons/md';
import { Input } from "@nextui-org/input";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Calendar,
  Button,
  Select,
  SelectItem,
} from "@nextui-org/react";
import { parseDate } from "@internationalized/date";
import { format } from "date-fns";

type Items = {
  name: string,
  description: string,
  count: number
};

type Guest = {
  age: Number | null;
  type: 'adult' | 'child';
};

const SearchPage2 = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [isCheckInOpen, setIsCheckInOpen] = useState(false);
  const [isCheckOutOpen, setIsCheckOutOpen] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);

  const getCalenderValue = (data: any) => data ? parseDate(format(new Date(data), "yyyy-MM-dd")) : null;
  const getInputValue = (data: any) => 
    data ? format(new Date(`${data}T00:00:00`), "MMMM d, yyyy") : "";
  
  const [items, setItems] = useState<Items[]>([
    { name: 'Adults', description: 'Ages 13 or above', count: 0 },
    { name: 'Children', description: 'Ages 2-12', count: 0 },
    { name: 'Infants', description: 'Under 2', count: 0 },
    { name: 'Pets', description: 'Service animal?', count: 0 },
  ]);



  const getGuestSummary = (): Guest[] => {
    const adultsCount = items.find(item => item.name === 'Adults')?.count || 0;
    const childrenCount = items.find(item => item.name === 'Children')?.count || 0;
    const infantsCount = items.find(item => item.name === 'Infants')?.count || 0;
    const petsCount = items.find(item => item.name === 'Pets')?.count || 0;

    const guestSummary: Guest[] = [];

    // Add adults
    for (let i = 0; i < adultsCount; i++) {
      guestSummary.push({ age: 18, type: 'adult' });
    }

    // Add children
    for (let i = 0; i < childrenCount; i++) {
      guestSummary.push({ age: 12, type: 'child' });
    }

    // Add infants
    for (let i = 0; i < infantsCount; i++) {
      guestSummary.push({ age: 2, type: 'child' });
    }

    return guestSummary;
  };

  console.log(getGuestSummary())

  const handleIncrement = (index: number) => {
    setItems(prevItems =>
      prevItems.map((item, i) =>
        i === index ? { ...item, count: item.count + 1 } : item
      )
    );
  };

  const handleDecrement = (index: number) => {
    setItems(prevItems =>
      prevItems.map((item, i) =>
        i === index ? { ...item, count: Math.max(0, item.count - 1) } : item
      )
    );
  };

  // Handle form submission
  const handleSubmit = () => {
    const query = new URLSearchParams({
      location,
      checkIn,
      checkOut,
      guests: JSON.stringify(getGuestSummary()),
    }).toString();
    navigate(`/rent/properties?${query}`);
  };

  return (
    <div className="flex w-full items-start rounded-full bg-white p-3 z-[40]" style={{ maxWidth: "950x", width: "950px", minWidth: "950px" }}>
      <Input
        classNames={{
          input: ["text-left text-default-500"],
          innerWrapper: "pb-0",
          inputWrapper: "bg-white shadow-none"
        }}
        className='w-[1200px] h-full'
        radius='full'
        size='sm'
        label="Location"
        fullWidth
        onChange={(e) => setLocation(e.target.value)}
      />

      {/* Check in */}
      <Popover
        placement="bottom-start"
        color="secondary"
        className='h-full'
        isOpen={isCheckInOpen}
        onOpenChange={(open) => setIsCheckInOpen(open)}
      >
        <PopoverTrigger>
          <Input
            radius="full"
            size="sm"
            label="Select Date"
            value={getInputValue(checkIn)}
            classNames={{
              input: ["text-left text-default-500"],
              innerWrapper: "pb-0",
              inputWrapper: "bg-white shadow-none"
            }}
            className="h-full"
            fullWidth
            endContent={
              <Button
                size="sm"
                radius="full"
                variant="light"
                className="text-base h-full"
                onClick={() => setIsCheckInOpen(true)}
                style={{ minWidth: "auto", transition: "background-color 0.3s ease" }}
              >
                <ChevronDown size={20} />
              </Button>
            }
          />
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <Calendar
            // value={getCalenderValue(checkIn)}
            onChange={(e: any) => setCheckIn(e)}
          />
        </PopoverContent>
      </Popover>

      <Select label="Select Term" size='sm' style={{ borderRadius: 30, background: "white", border: "none" }}>
        <SelectItem key={1}>1 Month</SelectItem>
        <SelectItem key={2}>2 Months</SelectItem>
        <SelectItem key={3}>3 Months</SelectItem>
        <SelectItem key={4}>4 Months</SelectItem>
        <SelectItem key={5}>5 Months</SelectItem>
        <SelectItem key={6}>6 Months</SelectItem>
      </Select>


      {/* Guests */}
      <Popover
        placement="bottom-start"
        color="secondary"
        className='h-full'
        isOpen={isGuestsOpen}
        onOpenChange={(open) => setIsGuestsOpen(open)}
      >
        <PopoverTrigger>
          <Input
            radius='full'
            size='sm'
            label='Guests'
            value={items
              .filter(item => item.count > 0)
              .map(item => `${item.name}: ${item.count}`)
              .join(', ') || ""}
            fullWidth
            classNames={{
              input: ["text-left text-default-500"],
              innerWrapper: "pb-0",
              inputWrapper: "bg-white shadow-none"
            }}
            className='h-full'
            endContent={
              <Button
                size="sm"
                radius="full"
                variant="light"
                className="text-base h-full"
                onClick={() => setIsGuestsOpen(true)}
                style={{ minWidth: "auto", transition: "background-color 0.3s ease" }}
              >
                <ChevronDown size={20} />
              </Button>
            }
          />
        </PopoverTrigger>
        <PopoverContent className="p-0">
          {items.map((item, index) => (
            <div key={item.name} className="grid grid-cols-[150px_130px]  w-full justify-between items-center ml-5 mt-3 mb-3">
              <div className="flex-1">
                <div className="font-semibold">{item.name}</div>
                <div style={{ color: "grey", fontSize: 14 }} className="text-sm text-muted-foreground">{item.description}</div>
              </div>
              <div className="grid grid-cols-[30px_50px_30px]  items-center">
                <Button
                  variant="flat"
                  style={{ width: 30, minWidth: 30 }}
                  className="h-8  rounded-full flex items-center p-0 justify-center"
                  onClick={() => handleDecrement(index)}
                  disabled={item.count === 0}
                >
                  <Minus className="h-4 w-4" />
                </Button>
                <span className="text-center">{item.count}</span>
                <Button
                  variant="flat"
                  style={{ width: 30, minWidth: 30 }}
                  className="h-8 bg-black text-white rounded-full flex items-center  p-0  justify-center"
                  onClick={() => handleIncrement(index)}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </div>
            </div>
          ))}
        </PopoverContent>
      </Popover>

      <Button
        color="primary"
        radius="full"
        size="lg"
        type="button"  // Change to button to prevent form submission
        className="text-xl"
        fullWidth
        isIconOnly
        onClick={handleSubmit}  // Call handleSubmit on click
      >
        <MdSearch size={20} />
      </Button>
    </div>
  );
};

export default SearchPage2;
