import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./globals.css"
import { NextUIProvider } from "@nextui-org/react";
import RentPage from "./app/rent/page";
import AboutPage from "./app/about/page";
import FAQPage from "./app/frequent-ask-questions/page";
import PricingPage from "./app/pricing/page";
import LegalPage from "./app/legal/page";
import ContactPage from "./app/contact/page";
import ChatPage from "./app/chat/page";
import PropertiesPage from "./app/rent/properties/page";
import PropertyPage from "./app/rent/properties/[id]/page";
import Learn from "./app/learn/page";

function App() {
  return (
    <Router>
      <NextUIProvider>
      <Routes>
        {/* Route for each page, matching folder name to URL */}
        <Route path="/" element={<RentPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/learn" element={<Learn />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/legal" element={<LegalPage />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/rent/properties" element={<PropertiesPage />} />
        <Route path="/rent/properties/:id" element={<PropertyPage />} />

        {/* Optional: Add a default route or 404 page */}
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
      </NextUIProvider>
    </Router>
  );
}

export default App;
