import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SearchPage from '../components/Search';
import { Tab, Tabs } from '@nextui-org/react';
import SearchPage2 from '../components/Search2';


const RentPage = () => {
  return (
    <div className="bg-black flex flex-col h-screen w-full justify-between">
      <Navbar />
      <div
        style={{ marginTop: -100 }}
        className="absolute inset-x-0 left-1/2 top-1/2 right-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center  w-full h-full flex-1 z-10 max-w-4xl mx-auto px-4 sm:px-6"
      >
        <div className='h-1/4' style={{marginBottom: -30}}>
          <img src="/logo.png" width={80} height={80} alt="about-picture" />
        </div>

        <Tabs
          color="primary"
          size="md"
          aria-label="Tabs colors"
          radius="lg"
          className="max-w-lg"
          fullWidth
          style={{marginTop: -20, marginBottom: 20}}
        >
          <Tab key={"mid"} title="Hotel">
            <SearchPage />
          </Tab>
          <Tab key={"short"} title="Rental">
            <SearchPage />
          </Tab>
          <Tab key={"short2"} title="Monthly">
            <SearchPage2 />
          </Tab>
          {/* <Tab key={"mid"} title="Rentals">
              <SearchBar2 />
            </Tab> */}

          {/* <Tab key={"mid3"} title="Cars">
              <SearchBar3 />
            </Tab>  */}
        </Tabs>
      </div>
      <Footer />
    </div>
  );
};

export default RentPage;