
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import React from 'react'
import {Tabs, Tab} from "@nextui-org/tabs";

const AboutPage = () => {
  return (
    <div className='bg-white flex flex-col h-screen w-full justify-between'>
        <Navbar/>
            <div className='h-full w-full !max-w-3xl items-center px-4 py-8 pt-12 mx-auto sm:px-6 flex flex-col gap-8 text-md'>
                <h1 className="pb-2 w-full text-center text-black text-2xl font-bold">
                    Hotel Now
                </h1>
                <Tabs
                color='primary'
                size="lg"
                aria-label="Tabs colors"
                radius="lg"
                className="max-w-3xl px-20 h-auto"
                fullWidth
                >
                <Tab key="privacy-policy" title="About Us">
                    hotel now is your all-in-one compliance solution for residential home
                    rentals across all 50 states in America. Our platform offers short term,
                    mid term, and long term rentals with flexible options to suit your
                    needs. For short term rentals , we offer the option to rent from 1 day
                    to 30 days, while mid term rentals offer a minimum of 1 month to 12
                    months , and long term rentals offer 1 year minimum and up to 5 years
                    maximum. With built-in tenant screening and a commitment to ensuring
                    full compliance with state and local regulations, hotel now provides a
                    seamless, trusted, and secure experience for both landlords and tenants.
                </Tab>
                <Tab key="terms-of-use" title="Our Mission">
                    Our mission is to revolutionize the residential real estate market by
                    providing a comprehensive, user centric platform that simplifies and
                    streamlines the rental process for tenants, landlords, property
                    managers, and investors. We strive to leverage innovative technology and
                    exceptional service to make renting and investing in residential
                    properties more accessible, efficient, and profitable for all parties
                    involved. By continuously expanding our suite of integrated products and
                    services and adapting to the unique needs of each market we serve, we
                    aim to become the leading end-to-end solution for residential rentals in
                    the United States of America.
                </Tab>
                <Tab key="fair-housing" title="Our Vision">
                    Our vision is to be the trusted leader in providing innovative,
                    end-to-end solutions for residential real estate rentals. We aspire to
                    create a seamless, technology driven ecosystem that empowers tenants,
                    landlords, property managers, and investors to achieve their goals with
                    ease and efficiency. We envision a future where renting and investing in
                    residential properties is easy and accessible to everyone, and where our
                    platform is synonymous with trust, reliability, and success. Through our
                    commitment to innovation and customer satisfaction, we will transform
                    the way that people rent and invest in real estate, creating value for
                    communities.
                </Tab>
                <Tab key="cookies-policy" title="Our Values">
                    We value our landlords, tenants, communities, and government partners.
                    We believe in fostering strong relationships built on trust,
                    transparency, and respect. For our landlords, we provide a platform that
                    makes it easy to list and manage properties while maximizing your rental
                    income. Our tenants benefit from a wide selection of compliant rentals
                    and a user friendly booking experience. We are committed to enhancing
                    the communities we serve by promoting responsible renting practices and
                    supporting local economies. We also work closely with government to
                    ensure that all of our rentals are fully compliant with state and local
                    regulations.
                </Tab>
                </Tabs>
            </div>
        <Footer/>
    </div>
  )
}

export default AboutPage