import TabSection from "./components/TabSection";

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import React from 'react'
import { Tabs, Tab } from "@nextui-org/tabs";

const Learn = () => {
  return (
    <div className='bg-white flex flex-col h-screen w-full justify-between'>
      <Navbar />
      <div className='bg-white h-full w-full !max-w-3xl items-center px-4 py-8 pt-12 mx-auto sm:px-6 flex flex-col gap-8 text-md'>
        <h1 className="pb-2 w-full text-center text-black text-2xl font-bold">
          Learn Our Terms
        </h1>

        <TabSection />
      </div>
      <Footer />
    </div>
  )
}
export default Learn;
